.img_main_slide{
    width: 100%;
}

.main_slide{
    width: 96%;
    margin-inline: auto;
    border-radius: 0 0 30px 30px;
    overflow: hidden;
}

.wrapper_contain_slide{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.wrapper_contain_slide:after{
    background-image: url('../../public/images/rectangle.png');
    background-size: contain;
    width: 100%;
    content: "";
    height: 100%;
    position: absolute;
    background-repeat: no-repeat;
}

.title_slide{
    position: absolute;
    top: 55%;
    left: 1rem;
    font-family: "Rubik-Bold";
    color: var(--white);
    font-size: 2.3rem;
    z-index: 1;
}

.description_slide{
    position: absolute;
    top: 75%;
    left: 1rem;
    font-family: "Rubik-Regular";
    color: var(--white);
    font-size: 1.3rem;
    z-index: 1;
}

.certificacion{
    width: 96%;
    margin: 2rem auto -2rem auto;
    display: flex;
}

.wrapper_description_certificacion{
    padding-left: 4rem;
    margin-top: 2rem;
}

.contenttitlecertificacion{
    width: 100%;
    display: flex;
    flex-direction: column;
}

.description_esquemas{
    white-space: break-spaces;
    text-align: justify;
}

.link_quienes_somos{
    color: var(--blue-light);
    font-family: "Rubik-Bold";
}

.description_image_quienes_somos{
    width: 70%;
    text-align: left;
    margin-inline: auto;
    top: -5rem;
    position: relative;
    background: var(--white);
    padding: 1rem;
    border-radius: 7px;
    box-shadow: 0px 0px 5px;
}

.description_image_quienes_somos::after{
    content: 'Hola';
    font-size: 0;
    width: 100%;
    display: block;
    border-bottom: 3px solid var(--blue-light);
}

.formcertified{
    display: flex;
    width: 96%;
    margin-inline: auto;
    background-image: url('../../public/images/fondo-form-home.png');
    background-repeat: no-repeat;
    aspect-ratio: 3/1;
    background-size: cover;
    margin-block: 2rem;
    max-height: 430px;
    min-width: 1259px;
    border-radius: 30px;
    overflow: hidden;
}

.formcertified::after{
    content: 'hola';
    background-image: url('../../public/images/rectangle1.png');
    background-size: cover;
    background-repeat: no-repeat;
    width: 96%;
    position: absolute;
    height: 100%;
    font-size: 0;
    max-height: 430px;
    min-width: 1259px;
    border-radius: 30px;
    overflow: hidden;
}

.formcertified_desc, .formcertified_form{
    display: flex;
    flex-direction: column;
    width: 50%;
    z-index: 1;
}

.formcertified_form .loading{
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    border: 3px solid;
    border-color: var(--blue-light) var(--blue-light) transparent transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    margin: auto;
}

.formcertified_form .loading::after, .formcertified_form .loading::before{
    content: '';  
    box-sizing: border-box;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    border: 3px solid;
    border-color: transparent transparent var(--blue) var(--blue);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    box-sizing: border-box;
    animation: rotationBack 0.5s linear infinite;
    transform-origin: center center;
}

.formcertified_form .loading::before {
    width: 32px;
    height: 32px;
    border-color: #FFF #FFF transparent transparent;
    animation: rotation 1.5s linear infinite;
}      

.formcertified_desc{
    align-items: center;
    justify-content: center;
    color: var(--white);
    margin: 0 1rem 3rem 1rem;
}

.formcertified_desc_img{
    width: 60px;
    height: 64px;
}

.formcertified_desc_title{
    font-family: "Rubik-Bold";
    font-size: 1.5rem;
    max-width: 300px;
    text-align: center;
    margin-block: 1.5rem;
}

.formcertified_form{
    background-color: var(--white);
    margin: 3rem 3rem 3rem 0;
    border-radius: 30px;
}

.formcertified_form_title{
    font-family: "Rubik-Bold";
    font-size: 1.5rem;
    color: var(--blue);
    padding: 0 0 0 3rem;
    margin-top: 2rem;
}

.formcertified_form_subtitle{
    font-family: "Rubik-Regular";
    font-size: 1rem;
    padding: 0 0 0 3rem;
    margin-block: 1rem;
}

.formcertified_form_subsubtitle{
    font-family: "Rubik-Bold";
    font-size: 1.3rem;
    color: var(--blue);
    padding: 0 0 0 3rem;
    margin-top: 1.5rem;
}

.formcertified_form_list, .formcertified_form_text{
    font-family: "Rubik-Regular";
    font-size: 1rem;
    margin: 0 0 0 3rem;
    border-radius: 5px;
    border: 1px solid #D9D9D9;
    
    margin-block: 1rem;
}

.formcertified_form_list{
    height: 3rem;
    width: 12rem;
}

.formcertified_form_text{
    width: 60%;
    height: 3rem;
    padding-left: 10px;
}

.formcertified_form_button{
    background-color: var(--blue-light);
    width: 16rem;
    border: none;
    margin: auto;
    height: 3rem;
    border-radius: 5px;
    font-family: "Rubik-Bold";
    font-size: 17px;
    color: var(--white);
}

.formcertified_form_button:hover{
    box-shadow: 3px 3px 5px #000;
    transition-duration: 0.3s;
    cursor: pointer;
}

.formcertified_form .certified{
    font-family: "Rubik-Regular";
    color: var(--blue);
    font-size: 1.3rem;
    margin-top: 5%;
    text-align: center;
}

.formcertified_form .label{
    font-family: "Rubik-Regular";
    color: var(--blue);
    font-size: 1rem;
    margin-top: 1%;
    display: inline-flex;
    margin-left: 1rem;
}

.formcertified_form .value{
    font-family: "Rubik-Bold";
    color: var(--blue);
    font-size: 1rem;
    margin-top: 1%;
    display: inline-flex;
    text-align: center;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.esquemas_description{
    width: 100%;
    background-color: var(--blue-extra-light);
    display: flex;
    width: 100%;
    margin-inline: auto;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.contentfichas{
    background-color: var(--blue-extra-light);
    display: flex;
    width: 100%;
    margin-inline: auto;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.wrappertitlefichas{
    width: 96%;
    display: flex; 
    align-items: center;
    margin-top: 1rem;
}

.contenttitlefichas{
    width: 16%;
    display: flex;
    flex-direction: column;
}

.titlefichas{
    color: var(--blue);
    font-family: "Rubik-Bold";
    font-size: 1.3rem;
    display: flex;
}

.titlefichas::after{
    content: 'esquemas';
    border-bottom: 3px solid var(--blue-light);
    width: 2rem;
    position: relative;
    display: flex;
    font-size: 0px;
    transform: translatey(-40%);
    margin-left: 1rem;
}

.subtitlefichas{
    color: var(--blue);
    font-family: "Rubik-Bold";
    font-size: 1.8rem;
}

.descfichas{
    width: 100%;
    margin-left: 2%;
    text-align: justify;
    font-family: "Rubik-Regular";
    font-size: 1rem;
}

.ficha{
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-inline: 3%;
    margin-block: 2rem;
    align-items: center;
    border-radius: 11px;
    width: 23rem;
    height: 20rem;
    box-shadow: 0 4px 6px #00000057;
}

.imgficha{
    width: 5rem;
    height: 6rem;
}

.titleficha{
    text-align: center;
    font-family: "Rubik-Bold";
    color: var(--blue);
    font-size: 1.3rem;
    padding-inline: 1rem;
}

.linkficha{
    font-family: "Rubik-Bold";
    color: var(--blue-light);
    font-size: 1rem;
    width: 100%;
    text-align: right;
    padding-right: 5rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.linkficha::after{
    content: url('../../public/images/arrow-right.png');
    margin-left: 0.5rem;
    top: 3px;
    position: relative;
}

.wrapper_contain_slide_bottom{
    background-color: var(--blue);
    color: var(--white);
    display: flex;
    padding-block: 2rem;
    justify-content: center;
    align-items: center;
    gap: 8rem;
}

.wrapper_contain_slide_bottom img{
    width: 261px;
    height: 267px;
    border-radius: 25px;
}

.containinfo{
    max-width: 35rem;
    display: flex;
    flex-direction: column;
}

.description_slide_bottom{
    margin-bottom: 3rem;
    font-size: 1.3rem;
}

.name_slide_bottom{
    font-size: 1.3rem;
}

.profession_slide_bottom{
    font-size: 1.1rem;
}

@media (max-width: 1024px) {

    /*HOME*/
    .img_main_slide{
        height: 75vh;
        object-fit: cover;
    }

    .wrapper_contain_slide:after{
        background-size: cover;
        height: 105%;
    }

    .title_slide{
        position: absolute;
        top: 50%;
        left: 1rem;
        font-family: "Rubik-Bold";
        color: var(--white);
        font-size: 1.7rem;
        z-index: 1;
    }
    
    .description_slide{
        position: absolute;
        top: 70%;
        left: 1rem;
        font-family: "Rubik-Regular";
        color: var(--white);
        font-size: 1.rem;
        z-index: 1;
    }

    .certificacion{
        flex-direction: column;
        margin: 2rem auto 2rem auto;
    }

    .certificacion img{
        width: 100%;
    }

    .wrapper_description_certificacion{
        margin-top: 0;
        padding-left: 0;
    }

    .formcertified_form{
        width: 96%;
        margin-inline: auto;
    }

    .formcertified{
        flex-direction: column;
        background-image: none;
        max-height: none;
        min-width: auto;
        width: 96%;
        height: auto;
        position: relative;
        aspect-ratio: auto;
        background: var(--blue);
    }
    .formcertified>div{
        width: 95%;
        margin-inline: auto;
        min-height: 220px;
    }

    .formcertified_form_button{
        margin: 1rem auto;
    }

    .formcertified_form .certified{
        font-size: 1rem;
    }
    
    .formcertified_form .label{
        font-size: 1rem;
    }
    
    .formcertified_form .value{
        font-size: 1rem;
    }

    .wrappertitlefichas{
        flex-direction: column;
    }

    .contenttitlefichas{
        width: 100%;
    }

    .formcertified_desc{
        margin: 1rem 0;
    }

    .wrapper_contain_slide_bottom{
        flex-direction: column;
        gap: 2rem;
    }

    .description_slide_bottom{
        margin-bottom: 2rem;
    }

    .name_slide_bottom{
        font-weight: bold;
    }

    .containinfo{
        width: 90%;
        margin-inline: auto;
    }

    .login{
        justify-content: space-evenly;
    }
    /*HOME*/
}