.logo{
    height: fit-content;
    padding-block: 0.5rem;
}

.contentheader{
    width: 100%;
    background-color: var(--white);
    display: flex;
    width: 96%;
    justify-content: space-between;
    margin-inline: 2%;
    z-index: 100;
}

.content{
    display: flex;
    list-style: none;
    gap: 1rem;
    align-items: center;
}

.content .wrapper_items, .content .wrapper_login{
    display: flex;
    gap: 2rem;
}

.wrapper_items{
    font-family: "Rubik-Medium";
    font-size: 1rem;
    color: var(--blue);
}

.item{
    max-width: 140px;
    display: flex;
    align-items: center;
}

.item:hover{
    text-shadow: 0px 0px 5px var(--blue);
    transition-duration: 0.3s;
}

a{
    text-decoration: none;
}

.wrapper_login a{
    height: 2.6rem;
    width: 9.1rem;
}

.wrapper_login a:first-child{
    border: 2px solid var(--blue-light);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    color: var(--blue-light);
    font-family: "Rubik-Medium";
}

.wrapper_login a:last-child{
    border: 2px solid var(--blue-light);
    background-color: var(--blue-light);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    color: var(--white);
    font-family: "Rubik-Medium";
}

.wrapper_login a:hover{
    box-shadow: 3px 3px 5px #000;
    transition-duration: 0.3s
}