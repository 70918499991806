.burger, .burger_open {
    width: 55px;
    height: 55px;
    background: white;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    transition: all .5s ease;
    margin: 1em;
    border: none;
}

.strip {
    transition: all .5s ease;
}
.strip div {
    height: 3px;
    border-radius: 2px;
    background: var(--blue-light);
    margin: 10px;
    transition: all .55s cubic-bezier(0.075, 0.82, 0.165, 1);
    width: 40px;
}

.strip div {
    transition: all .65s cubic-bezier(0.075, 0.82, 0.165, 1)
}
.burger_open .strip div:first-child {
    transform: translateY(13px) rotate(0deg) ;
}
.burger_open .strip div:nth-child(2) {
    opacity: 0;
}
.burger_open .strip div:last-child {
    transform: translateY(-13px) rotate(90deg) ;
}
.burger_open .strip {
    transform: scale(.7) rotate(45deg);
    transition: all .25s ease .05s;
}

.headerMB{
    display: flex;
    flex-wrap: wrap;
    padding-inline: 2%;
    margin-inline: auto;
    padding-top: 0.5rem;
    justify-content: space-between;
    align-items: center;
}

.content{
    list-style: none;
    gap: 1rem;
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100vw;
    margin-inline: -2%;
    box-shadow: inset -1px 1px 12px 0px;
    padding-block: 2rem;
    padding-inline: 0;
    margin-block: 0;
}

.content .wrapper_items, .content .wrapper_login{
    display: flex;
    gap: 2rem;
    flex-direction: column;
}

.wrapper_items{
    font-family: "Rubik-Medium";
    font-size: 1rem;
    color: var(--blue);
    align-items: center;
}

.item{
    display: flex;
    align-items: center;
}

.item:hover{
    text-shadow: 0px 0px 5px var(--blue);
    transition-duration: 0.3s;
}

a{
    text-decoration: none;
}

.wrapper_login a{
    height: 2.6rem;
    width: 9.1rem;
}

.wrapper_login a:first-child{
    border: 2px solid var(--blue-light);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    color: var(--blue-light);
    font-family: "Rubik-Medium";
}

.wrapper_login a:last-child{
    border: 2px solid var(--blue-light);
    background-color: var(--blue-light);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    color: var(--white);
    font-family: "Rubik-Medium";
}

.wrapper_login a:hover{
    box-shadow: 3px 3px 5px #000;
    transition-duration: 0.3s
}