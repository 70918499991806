.container {
  width: 96%;
  margin-inline: auto;
}

.presentation {
  margin-top: 2.5rem;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-start;
  width: 96%;
  margin-inline: auto;
}

.presentation > div > p {
  font-family: "Rubik-Regular";
  font-weight: 400;
  font-size: 1rem;
  text-align: justify;
}

.subTitle {
  font-family: "Rubik-Bold";
  color: var(--blue);
  font-size: 1.8rem;
}

.mision {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  gap: 7rem;
  width: 96%;
  margin-inline: auto;
}

.mision img, .vision img {
  width: 50%;
}

.mision > div, .vision > div{
  width:50%;
}

.mision > div > p {
  font-family: "Rubik-Regular";
  color: var(--black);
  font-size: 1rem;
  text-align: justify;
}

.image {
  border-radius: 15px;
}

.vision {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  gap: 7rem;
  width: 96%;
  margin-inline: auto;
}

.vision > div > p {
  font-family: "Rubik-Regular";
  color: var(--black);
  font-size: 1rem;
  text-align: justify;
}

.generalObjective {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-start;
  width: 96%;
  margin-inline: auto;
}

.specificObjective {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-start;
  width: 96%;
  margin-inline: auto;
}

.specificContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-start;
}

.specificObjective .specificContainer > div > ul > li {
  margin-bottom: 1rem;
  text-align: justify;
}

.politicas {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-start;
  background-color: #F0F4F9;
}

.ourPoliticas {
  margin-top: 1rem;
  width: 96%;
  margin-inline: auto;
}

.politicasContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 5rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.politicaCard {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 26%;
  height: 181px;
  border-radius: 11px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25);
  font-family: "Rubik-Regular";
  font-weight: 700;
  color: #0D3880;
  cursor: pointer;
}

.politicaCard > p {
  text-align: center;
  margin-inline: 1rem;
}

.activePoliticaCard {
  background-color: #0D3880;
  color: white;
}

.responsability {
  margin-top: 4rem;
  width: 96%;
  margin-inline: auto;
}

.description {
  margin-top: 2rem;
  width: 96%;
  margin-inline: auto;
}

.description > ul > li {
  margin-bottom: 1rem;
  text-align: justify;
}

.derechos {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-start;
  background-color: #F0F4F9;
}

.derechosContainer {
  margin-top: 1rem;
  width: 96%;
  margin-inline: auto;
}

.derechosCardsContainer {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
}

.derechosCards {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  width: 596px;
  height: 326px;
  border-radius: 11px;
  background-color: white;
  gap: 1rem;
}

.linkficha{
  font-family: "Rubik-Bold";
  color: var(--blue-light);
  font-size: 1rem;
  width: 100%;
  text-align: right;
  padding-right: 5rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border: none;
  background-color: transparent;
}

.linkficha::after{
  content: url('../../../public/images/arrow-right.png');
  margin-left: 0.5rem;
  top: 3px;
  position: relative;
}

@media (max-width: 1024px) {
  
  /*ABOUT*/
  .mision, .vision{
      flex-direction: column;
      gap: 1rem;
  }
  .mision > div, .mision > img, .vision > div, .vision > img{
      width: 95%;
      margin-inline: auto;
  }
  .specificContainer {
    flex-direction: column;
    width: 90%;
  }
  .ourPoliticas {
    width: 90%;
  }
  .ourPoliticas > p{
    text-align: justify;
  }
  .politicaCard {
    width: 45%;
    margin-block: 1rem;
  }
  .politicasContainer {
    gap: 5%;
  }
  .derechosCardsContainer {
    flex-direction: column;
  }
  .derechosCards {
    width: 90%;
    margin-inline: auto;
  }
  .subTitle {
    text-align: center;
  }
  /*ABOUT*/
}