.register {
    width: 96%;
    display: flex;
    margin-inline: auto;
    background-image: url('../../public/images/sugerencias-e-ideas 1.png');
    background-size: cover;
    margin-block: 2rem;
    max-width: 1300px;
}

.background {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 1;
    display: flex;
    flex-direction: column;
    padding: 8px;
}

.infsection {
    /* width: 85%; */
    display: flex;
    justify-content: center;
    flex-direction: column;
    z-index: 2;
    margin: 18px auto 16px; 

}

.logo {
    width: fit-content;
    position: relative;
    margin-inline: auto;
    height: auto;
}

.description {
    width: 80%;
    color: var(--white);
    text-align: center;
    margin-inline: auto;
    margin-block: 2rem;
}

.formsection {
    width: 70%;
    z-index: 2;
    margin: auto;
    background-color: var(--white);
    border-radius: 10px;
    padding: 2rem;
    height: auto;
}

.title {
    display: block;
    font-size: 1.8rem;
    font-family: "Rubik-Bold";
    color: var(--blue);
    margin-bottom: 3rem
}

.labelForm {
    width: 45%;
    position: relative;
    display: inline-flex;
    color: var(--grey);
    font-size: 1rem;
    margin-bottom: 1rem;
}

.inputForm,
select {
    width: 52%;
    font-family: "Rubik-Regular";
    height: 48px;
    font-size: 1rem;
    color: var(--grey);
    border: 1px solid var(--grey);
    border-radius: 5px;
    margin-bottom: 2rem;
}

.errorForm {
    width: 60%;
    color: var(--red);
    display: block;
    text-align: center;
    margin-bottom: 1rem;
    margin-left: auto;
}

.btnClearSignature {
    width: 100px;
    padding: 0.5rem;
    border: none;
    background-color: var(--blue-light);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    color: var(--white);
    font-family: "Rubik-Medium";
    margin-left: auto;
    cursor: pointer;
}

.btnsubmit {
    width: 40%;
    padding: 1rem;
    border: 2px solid var(--blue-light);
    background-color: var(--blue-light);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    color: var(--white);
    font-family: "Rubik-Medium";
    margin-left: auto;
    cursor: pointer;
}

.tyc {
    text-decoration: underline;
    color: var(--blue-light);
    display: block;
    width: 100%;
    text-align: right;
    position: relative;
    margin-block: 3rem;
}

.btnsubmit:hover {
    box-shadow: 3px 3px 5px #000;
    transition-duration: 0.3s
}
.signatureContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.firma {
    width: 60%;
    max-width: 60%;
    border: 1px solid var(--blue);
    border-radius: 5px;
}


.lds_facebook {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds_facebook div {
    display: inline-block;
    position: absolute;
    left: 8px;
    width: 16px;
    background: var(--blue);
    animation: lds_facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}

.lds_facebook div:nth-child(1) {
    left: 8px;
    animation-delay: -0.24s;
}

.lds_facebook div:nth-child(2) {
    left: 32px;
    animation-delay: -0.12s;
}

.lds_facebook div:nth-child(3) {
    left: 56px;
    animation-delay: 0;
}

@keyframes lds_facebook {
    0% {
        top: 8px;
        height: 64px;
    }

    50%,
    100% {
        top: 24px;
        height: 32px;
    }
}

@media (max-width: 1024px) {
    .register {
        flex-direction: column;
        background-image: none;
    }

    .register::after {
        background-image: none;
    }

    .infsection {
        display: none;
    }

    .formsection {
        width: 100%;
        height: auto;
        padding: 0;
    }

    canvas {
        width: auto;
        margin-inline: auto;
        max-width: 96vw;
    }
}