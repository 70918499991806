.modal {
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  overflow: auto;
  max-width: 776px;
  width: 70%;
  margin: auto;
  border-radius: 25px;
  z-index: 9999;
  position: sticky;
  top: 8rem;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 60vh;
  direction:rtl; 
}

.modal::-webkit-scrollbar {
  width: 0.5rem;
}
 
.modal::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
 
.modal::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid transparent;
  border-radius: 10px;
}

.modal label {
  color: #0D3880;
  font-family: "Rubik-Regular";
  font-size: 22px;
  font-weight: 700;
  direction: ltr;
  text-align: left;
  display: block;
  padding: 0 20px 20px 20px;
}

.modal p {
  font-family: "Rubik-Regular";
  font-size: 17px;
  text-align: justify;
  direction: ltr;
  padding: 0 20px 20px 20px;
  white-space: pre-line;
}

.modal hr {
  height: 3px;
  background-color: #099ACB;
  border: none;
}

.modal button {
  cursor: pointer;
  position: sticky;
  margin-left: 93%;
  top: -0px;
  border: none;
  background-color: white;
  border-radius: 50%;
  padding: 0.5rem;
  box-shadow: 0px 0px 5px;
  width: 50px;
  height: 50px;
}

.modal button::after{
  content: url('../../public/images/Vector.png');
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #737373B2;
}

@media (max-width: 1024px) {
  .modal {
    width: 90%;
  }
}