@keyframes moverotate {
    0% {
        transform: rotate(-10deg);
    }
    50% {
        transform: rotate(10deg);
    }
    100% {
        transform: rotate(-10deg);
    }
}

.whatsapp_button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 60px;
    height: 60px;
    background-color: transparent;
    color: white;
    border: none;
    border-radius: 50%;
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 9;
    animation: moverotate 1s infinite;
}