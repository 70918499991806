.wrapperfooter{
    display: flex;
    flex-direction: row;
    padding: 3rem 2%;
    background-color: var(--blue-extra-light);
}
.logo{
    width: 160px;
    height: 68px;
}
.info{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.col{
    display: flex;
    flex-direction: column;
}

.title{
    font-size: 1.3rem;
    font-family: "Rubik-Bold";
    color: var(--blue);
    padding-bottom: 5px;
    border-bottom: 1px solid var(--blue-light);
    margin-bottom: 0.5rem;
}

.subitem{
    margin-block: 0.5rem;
    font-family: "Rubik-Regular";
    font-size: 1rem;
    color: var(--black);
}

.social{
    display: flex;
    flex-direction: row;
    gap: 1rem;
    margin-bottom: 2rem;
}

.social img{
    height: 20px;
}

.login{
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

.login a{
    height: 2.6rem;
    width: 9.1rem;
}

.login a:first-child{
    border: 2px solid var(--blue-light);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    color: var(--blue-light);
    font-family: "Rubik-Medium";
}

.login a:last-child{
    border: 2px solid var(--blue-light);
    background-color: var(--blue-light);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    color: var(--white);
    font-family: "Rubik-Medium";
}

.login a:hover{
    box-shadow: 3px 3px 5px #000;
    transition-duration: 0.3s
}