.img_main_slide{
    width: 100%;
}

.main_slide{
    width: 96%;
    margin-inline: auto;
    border-radius: 0 0 30px 30px;
    overflow: hidden;
}

.wrapper_contain_slide{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.wrapper_contain_slide:after{
    background-image: url('../../public/images/rectangle.png');
    background-size: contain;
    width: 100%;
    content: "";
    height: 100%;
    position: absolute;
    background-repeat: no-repeat;
}

.title_slide{
    position: absolute;
    top: 55%;
    left: 1rem;
    font-family: "Rubik-Bold";
    color: var(--white);
    font-size: 2.3rem;
    z-index: 1;
}

.description_slide{
    position: absolute;
    top: 75%;
    left: 1rem;
    font-family: "Rubik-Regular";
    color: var(--white);
    font-size: 1.3rem;
    z-index: 1;
}

.main_description{
    width: 96%;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-block: 1rem
}

.main_description .title{
    font-family: "Rubik-Bold";
    color: var(--blue);
    font-size: 2rem;
}

.main_description .description{
   font-family: "Rubik-Regular";
   font-size: 1rem;
}

.certificaciones{
    margin-bottom: 3rem;
}

.itemCertified{
    width: 96%;
    display: flex;
    margin-inline: auto;
    gap: 5rem;
    align-items: center;
    margin-block: 2rem;
}

.itemCertified > img{
    width: 50%;
}

.contentDscription{
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.title{
    font-family: "Rubik-Bold";
    color: var(--blue);
    font-size: 1.5rem;
}

.description{
    font-family: "Rubik-Regular";
    color: var(--black);
    font-size: 1rem;
}

/* Estilos para el modal */
.modalOverlayCertified {
    position: fixed !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7); /* Fondo oscuro semitransparente */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
}

.modalImage {
    width: 100%;
    height: auto;
    border-radius: 10px;
    margin-bottom: 15px;
}

/* Estilos para el modal */
.modalOverlayCertified {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7); /* Fondo oscuro semitransparente */
    display: fixed;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modalContent {
    background: #fff;
    /* padding: 20px; */
    border-radius: 10px;
    width: 90%;
    max-width: 500px;
    max-height: 95%;
    text-align: center;
    position: relative;
}

.modalContent iframe {
    padding: 8px;
}

.closeButton {
    position: absolute;
    top: -5px;
    right: -5px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    border-radius: 50%;
    background-color: red;
    color: var(--white);
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modalImage {
    width: 100%;
    height: auto;
    border-radius: 10px;
    margin-bottom: 15px;
}

.moreButton {
    background-color: var(--white);
    color: var(--blue);
    border: none;
    text-align: left;
}

.modalTitle {
    background-color: var(--blue);
    color: var(--white);
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px 10px 0 0;
}

.modalTitle h6 {
    font-size: 13px;
}


@media (max-width: 1024px) {

    .itemCertified{
        flex-direction: column;
    }
    .itemCertified > img{
        width: 90%;
        margin-inline: auto;
    }
    .contentDscription{
        width: 90%;
        margin-inline: auto;
    }
    .wrapper_contain_slide:after{
        background-size: cover;
        height: 105%;   
    }
    .title_slide{
        position: absolute;
        top: 50%;
        left: 1rem;
        font-family: "Rubik-Bold";
        color: var(--white);
        font-size: 1.7rem;
        z-index: 1;
    }
    
    .description_slide{
        position: absolute;
        top: 70%;
        left: 1rem;
        font-family: "Rubik-Regular";
        color: var(--white);
        font-size: 1.rem;
        z-index: 1;
    }
}